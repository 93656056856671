/* eslint-disable react/forbid-prop-types */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'styled-components';

import DataTable from '../../juristec-ui/core/DataTable/DataTable';
import IconButton from '../../juristec-ui/core/IconButton';
import Select from '../../juristec-ui/core/Select';
import Tooltip from '../../juristec-ui/core/Tooltip';
import TableCellPop from '../../juristec-ui/core/TableCellPop';
import TablePagination from '../../juristec-ui/core/TablePagination';
import TableFilters from '../Popovers/TableFilters';

import originIcon, { getOriginOptions, getConnectorOptions } from '../../juristec-ui/utils/originMap';

import { printSize, formatDateTime } from '../../juristec-ui/utils/functions/lab';
import compare from '../../utils/functions/sorting';

import {
  Trash, UpdateError, UpdateSuccess, UpdateWarning, UpdateInactive, TransferUser, Columns,
  NewColumn, Reverse,
} from '../../juristec-ui/icons';

import {
  MainContainer, TableOverFlow, IconWrapper, OwnerLabel, FooterContainer, Options,
} from './styled/ReportsTable.styled';

const ReportsTable = ({
  reports,
  instances,
  //
  getReports,
  deleteReport,
  resetReport,
  changeReportOwner,
  openReportStatus,
  openInactiveReportStatus,
  openReportColumns,
  openCustomColumns,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageQty, setPageQty] = useState(25);
  const [filters, setFilters] = useState({
    origin: { isOpen: false, selected: [], isDirty: false },
    status: { isOpen: false, selected: [], isDirty: false },
    connector: { isOpen: false, selected: [], isDirty: false },
    companyId: { isOpen: false, selected: [], isDirty: false },
    filename: { isOpen: false, selected: '', isDirty: false },
    inactive: { isOpen: false, selected: false, isDirty: false },
  });

  useEffect(() => {
    const fetchList = async () => {
      await getReports(page, filters, pageQty, false);
    };
    if (!reports.list || !reports.list[page]) {
      fetchList();
    }
  }, [page, filters]);

  useEffect(() => {
    setPage(0);
    const fetchList = async () => {
      await getReports(0, filters, pageQty, true);
    };
    if (filters.origin.isDirty
      || filters.status.isDirty
      || filters.connector.isDirty
      || filters.companyId.isDirty
      || filters.filename.isDirty
      || filters.inactive.isDirty
    ) {
      fetchList();
    }
  }, [
    filters.origin.selected,
    filters.status.selected,
    filters.connector.selected,
    filters.companyId.selected,
    filters.filename.selected,
    filters.inactive.selected,
  ]);

  const handlePageQty = async (selected) => {
    setPageQty(selected.value);
    setPage(0);
    await getReports(0, filters, selected.value, true);
  };

  /**
   * Sets status icon with related status
   * @param {object} report report info
   * @returns An icon with the corresponding status
   */
  const getStatusBadge = (report) => {
    const fetchList = async () => {
      setPage(0);
      await getReports(0, filters, pageQty, true);
    };
    const openInfo = () => openReportStatus(report, fetchList);

    if (!report.active) {
      if (report.deletedAt) {
        return (
          <IconButton color="gray" onClick={() => openInactiveReportStatus(report, true, fetchList)}>
            <UpdateError />
          </IconButton>
        );
      } return (
        <IconButton color="gray" onClick={() => openInactiveReportStatus(report, false, fetchList)}>
          <UpdateInactive />
        </IconButton>
      );
    }

    switch (report.status) {
      case 'ERROR':
        return (
          <IconButton color="error" onClick={openInfo}>
            <UpdateError />
          </IconButton>
        );
      case 'WARNING':
        return (
          <IconButton color="warning" onClick={openInfo}>
            <UpdateWarning />
          </IconButton>
        );
      default:
        return (
          <IconButton color="success" onClick={openInfo}>
            <UpdateSuccess />
          </IconButton>
        );
    }
  };

  const ReportsOptions = ({ report }) => {
    const fetchLists = async () => {
      setPage(0);
      await getReports(0, filters, pageQty, true);
    };

    const getDeleteHandler = () => {
      const fetchList = async () => {
        setPage(0);
        await getReports(0, filters, pageQty, true);
      };
      deleteReport(report, fetchList);
    };

    const getChangeOwner = () => {
      const fetchList = async () => {
        setPage(0);
        await getReports(0, filters, pageQty, true);
      };
      changeReportOwner(report, fetchList);
    };

    const getReportColumns = () => {
      openReportColumns(report, fetchLists);
    };

    const getCustomColumns = () => {
      openCustomColumns(report, fetchLists);
    };
    const getResetHandler = () => {
      const fetchList = async () => {
        setPage(0);
        await getReports(0, filters, pageQty, true);
      };
      resetReport(report, fetchList);
    };

    return (
      <Options>
        <Tooltip text="Alterar proprietário">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getChangeOwner}>
            <TransferUser />
          </IconButton>
        </Tooltip>
        <Tooltip text="Colunas">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getReportColumns}>
            <Columns />
          </IconButton>
        </Tooltip>
        <Tooltip text="Colunas Customizadas">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getCustomColumns}>
            <NewColumn />
          </IconButton>
        </Tooltip>
        <Tooltip text="Resetar relatório">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getResetHandler}>
            <Reverse />
          </IconButton>
        </Tooltip>
        <Tooltip text="Apagar relatório">
          <IconButton variant="pattern" shape="rounded" color="primary" onClick={getDeleteHandler}>
            <Trash />
          </IconButton>
        </Tooltip>
      </Options>
    );
  };

  ReportsOptions.propTypes = {
    report: PropTypes.shape({}).isRequired,
  };

  /**
   * Find company name by company id
   * @param {string} companyId Company unique identifier
   * @returns {string} Company name
   */
  const getCompanyName = (companyId) => {
    const company = instances.find((ins) => ins.id === companyId);
    return company?.name || companyId;
  };

  const getCompaniesOpt = () => {
    const temp = instances.map((ins) => ({
      id: ins.id, label: ins.name, value: ins.id,
    }));
    return temp.sort((a, b) => (compare(a.label, b.label)));
  };

  const toggleFilter = (field, state) => {
    setFilters((f) => ({
      ...f,
      [field]: {
        ...f[field],
        isOpen: state !== undefined ? state : !f[field].isOpen,
      },
    }));
  };

  const handleFilter = (field, selected) => {
    setFilters((f) => ({
      ...f,
      [field]: {
        ...f[field],
        selected,
        isDirty: true,
      },
    }));
  };

  /** Arrangement of table columns */
  const columns = useMemo(() => [
    {
      field: 'origin',
      label: 'Origem',
      valueGetter: (param) => <IconWrapper>{originIcon(param.origin)}</IconWrapper>,
      tools: (
        <TableFilters
          field="origin"
          isOpen={filters?.origin?.isOpen || false}
          toggle={toggleFilter}
          options={getOriginOptions || []}
          selected={filters?.origin?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'connector',
      label: 'Conector',
      valueGetter: (param) => param.connector,
      tools: (
        <TableFilters
          field="connector"
          isOpen={filters?.connector?.isOpen || false}
          toggle={toggleFilter}
          options={getConnectorOptions || []}
          selected={filters?.connector?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'companyId',
      label: 'Empresa',
      valueGetter: (param) => <TableCellPop text={getCompanyName(param.companyId)} />,
      tools: (
        <TableFilters
          field="companyId"
          isOpen={filters?.companyId?.isOpen || false}
          toggle={toggleFilter}
          options={getCompaniesOpt() || []}
          selected={filters?.companyId?.selected || []}
          handleFilter={handleFilter}
          popDirection="bottom-start"
          isRadio
        />
      ),
    },
    {
      field: 'filename',
      label: 'Arquivo',
      valueGetter: (param) => (
        <>
          <TableCellPop text={param.filename} />
          <OwnerLabel>{param.ownerEmail || 'Usuário não encontrado'}</OwnerLabel>
        </>
      ),
      tools: (
        <TableFilters
          field="filename"
          isOpen={filters?.filename?.isOpen || false}
          toggle={toggleFilter}
          selected={filters?.filename?.selected || ''}
          handleFilter={handleFilter}
          isText
        />
      ),
    },
    {
      field: 'size',
      label: 'Tamanho',
      valueGetter: (param) => (param.size ? printSize(param.size).replace('.', ',') : '-'),
      sortable: true,
    },
    {
      field: 'updatedAt',
      label: 'Última atualização',
      valueGetter: (param) => formatDateTime(param.updatedAt, { time: 'half' }),
    },
    {
      field: 'status',
      label: 'Status',
      valueGetter: (param) => getStatusBadge(param),
      tools: (
        <TableFilters
          field="status"
          isOpen={filters?.status?.isOpen || false}
          toggle={toggleFilter}
          options={[
            { id: 'status_success', label: 'Sucesso', value: 'SUCCESS' },
            { id: 'status_warning', label: 'Atenção', value: 'WARNING' },
            { id: 'status_error', label: 'Erro', value: 'ERROR' },
          ]}
          extraToggle={{
            label: 'Exibir apenas inativos',
            value: filters?.inactive?.selected || false,
            field: 'inactive',
          }}
          selected={filters?.status?.selected || []}
          handleFilter={handleFilter}
        />
      ),
    },
    {
      field: 'options',
      label: 'Opções',
      valueGetter: (param) => <ReportsOptions report={param} />,
    },
  ], [reports, filters]);

  return (
    <MainContainer>
      <TableOverFlow>
        <DataTable
          columns={columns}
          rowData={reports.list[page] || []}
          defaultSortField="updatedAt"
          defaultSortOrder="descending"
          headerColor="transparent"
          rowColor={theme.tableBackground}
          theadStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: theme.background,
          }}
        />
      </TableOverFlow>
      <FooterContainer>
        <TablePagination
          page={page}
          setPage={setPage}
          totalPages={reports.pageTotal - 1}
          useGoToPage
        />
        <span style={{ marginLeft: '1rem' }}>
          Itens por página:
        </span>
        <Select
          value={{ label: pageQty, value: pageQty }}
          onChange={handlePageQty}
          options={[
            { label: '25', id: '25-itens', value: 25 },
            { label: '50', id: '50-itens', value: 50 },
            { label: '100', id: '100-itens', value: 100 },
          ]}
          style={{
            marginLeft: '1rem',
            minWidth: '50px',
            width: '60px',
          }}
        />
      </FooterContainer>
    </MainContainer>
  );
};

ReportsTable.propTypes = {
  /** Array of reports info */
  reports: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
    pageTotal: PropTypes.number,
  }),
  /** Array of instances */
  instances: PropTypes.arrayOf(PropTypes.shape({})),
  /** Requests all the reports */
  getReports: PropTypes.func,
  /** Handles the removal of a report */
  deleteReport: PropTypes.func,
  /** Handles the change of report owner */
  changeReportOwner: PropTypes.func,
  /** Handles the modal with report info */
  openReportStatus: PropTypes.func,
  /** Handles the modal with inactive report info */
  openInactiveReportStatus: PropTypes.func,
  /** Handles the modal with report columns */
  openReportColumns: PropTypes.func,
  /** Handles the modal with report custom columns */
  openCustomColumns: PropTypes.func,
  /** Handles the resetting of a report */
  resetReport: PropTypes.func,
};

ReportsTable.defaultProps = {
  reports: null,
  instances: [],
  getReports: () => {},
  deleteReport: () => {},
  changeReportOwner: () => {},
  openReportStatus: () => {},
  openInactiveReportStatus: () => {},
  openReportColumns: () => {},
  openCustomColumns: () => {},
  resetReport: () => {},
};

export default ReportsTable;
