/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import isEqual from 'lodash.isequal';
import KpiRender from './KpiRender';
import {
  StyledContainer,
  StyledGridContainerCreateKpi,
  OuterKpiCreate,
  LegendsContainer,
  GenChartAlert,
  IconCircle,
} from './styled/KpiCreate.styled';
import {
  Eye2, Warning, NoFileKpis,
} from '../../juristec-ui/icons';
import { AuthContext } from '../../context/AuthProvider';
import handleStyleConf from './handleStyleConfig';
import { error } from '../../label';
import KpiSideBar from './KpiSideBar';
import { AlertContext } from '../../context/AlertProvider';
import { ModalContext } from '../../context/ModalProvider';
import Button from '../../juristec-ui/core/Button';
import IconButton from '../../juristec-ui/core/IconButton';
import Tooltip from '../../juristec-ui/core/Tooltip';
import Loader from '../../juristec-ui/core/Loader';
import Select from '../../juristec-ui/core/Select';
import InputTextLine from '../../juristec-ui/core/InputTextLine';
import DataTable from '../../juristec-ui/core/DataTable';
import getDefaultStyle from '../../juristec-ui/kpis/utils/chartTools/getDefaultStyle';

import backtable from '../../assets/CreateKPITableImage.png';
import formatValue from '../../utils/functions/formatTableValue';

import useGridKpi from '../../hooks/useGridKpi';
import { TourContext } from '../../context/TourProvider';
import { FilesContext } from '../../context/FilesProvider';

//
import KpiCarousel from '../KpiCarousel';
import KpiCreateToolbar from '../Toolbars/KpiCreateToolbar';

const randomColor = () => (
  // eslint-disable-next-line no-bitwise
  '#000000'.replace(/0/g, () => (~~(Math.random() * 16)).toString(16))
);

const dataFrameToNewDataTable = (dataFrame, theme) => {
  const getColor = (item) => {
    switch (dataFrame.types[item]) {
      case 'int64':
        return theme.typeNumber;
      case 'float64':
        return theme.typeNumber;
      case 'datetime64[ns]':
        return theme.typeDate;
      default:
        return theme.typeText;
    }
  };

  const columns = dataFrame.columns.reduce((aux, column) => {
    if (column === 'index') return aux;
    aux.push({
      field: column,
      label: column,
      dataColor: getColor(column),
      sortable: true,
      valueGetter: (p) => formatValue(p[column], dataFrame.types[column]),
    });
    return aux;
  }, []);

  const rowData = dataFrame.data.map((dataList) => (
    dataList.reduce((rowObj, data, index) => {
      // eslint-disable-next-line no-param-reassign
      rowObj[dataFrame.columns[index]] = data;
      return rowObj;
    }, {})
  ));
  return { columns, rowData, types: dataFrame.types };
};

/** =========================================================================================== */
export default function KpiCreate(props) {
  const {
    goBack, // used to define if a dashboard is opened or not
    dashboardKey, // firestoreId from openedDashboard
    kpiKey,
    history,
    handleDynamicKpi,
  } = props;

  const theme = useTheme();
  const isMounted = useRef(false);
  const { currentUser, user } = useContext(AuthContext);
  const [{
    kpisLayout,
    dashboardDoc,
    isLoading: kpiLoading,
  }, gridKpiAPI] = useGridKpi(currentUser.uid, dashboardKey, currentUser, user);

  const { setAlertConfig } = useContext(AlertContext);
  const { setModalConfig } = useContext(ModalContext);
  const { state: filesState, filesAPI } = useContext(FilesContext);
  const { files: fileTreeOptions } = filesState;

  const [refreshSideBar, setRefreshSideBar] = useState(true);
  const [selectedDatabase, setSelectedDatabase] = useState({ label: '', value: '', id: '' });
  const [dataFrame, setDataFrame] = useState(null);
  const [pivotTable, setPivotTable] = useState(null);
  const [metaGen, setMetaGen] = useState(null);
  const [metaGridInfo, setMetaGridInfo] = useState(null);
  const [chartType, setChartType] = useState(null);
  const [showGenChartAlert, setShowGenChartAlert] = useState(false);

  // const [fileTreeOptions, setFileTreeOptions] = useState([]);
  const [kpiEditOptions, setKpiEditOptions] = useState(null);

  const [dataFrameOptions, setDataFrameOptions] = useState([]);

  const [styleConfig, setStyleConfig] = useState({});
  const [editLoad, setEditLoad] = useState(false);
  // const [dashboardName, setDashboardName] = useState('');

  const { tourOpen, nextStep } = useContext(TourContext);

  // Para perguntar antes de sair
  const location = useLocation();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState(location);
  const blockNav = useRef(true);

  useEffect(() => {
    if (!user || !currentUser) return;

    if (filesState.started) return;
    (async () => {
      const { error: er, msg } = await filesAPI.init();
      if (er) {
        setAlertConfig({
          type: 'error',
          text: error.generic,
          child: (
            <div style={{
              width: '320px', display: 'flex', justifyContent: 'center', margin: '0px auto',
            }}
            >
              <span>{msg}</span>
            </div>
          ),
        });
      }
    })();
  }, [filesAPI, currentUser, user, filesState.started]);

  useEffect(() => {
    gridKpiAPI.loadLayoutAndDash();
    if (kpiKey !== 'new') {
      gridKpiAPI.getKpiInfo(kpiKey).then((kpi) => {
        if (kpi) {
          const file = fileTreeOptions.find((f) => f.file_id === kpi.database);
          setEditLoad(true);
          setSelectedDatabase(
            { label: file?.filename || '', value: kpi.database } || { label: '', value: '' },
          );
          // setSelectedFileOwner(kpi.meta?.ownerId || '');
          setMetaGen(kpi.meta || null);
          setStyleConfig({
            ...kpi.style,
            name: kpi.name,
            type: kpi.type || 'Table',
          });
          setPivotTable(kpi.data || null);
          setChartType(kpi.type || 'Table');
          setKpiEditOptions(kpi);
        }
      });
    } else if (!chartType) setChartType('Table');
  }, [gridKpiAPI, kpiKey]);

  function fillColorsWithRandoms(obj) {
    const aux = { ...obj };
    if (pivotTable) {
      // Vai criar cores randomicas para quando o cara criar o gráfico n começar tudo preto...
      // console.log('criando cores randomicas "por coluna"');
      const tmp = {};
      if (!styleConfig.Colors) styleConfig.Colors = {};
      if (!styleConfig.PalletsControl) aux.PalletsControl = 'default'; // || custom
      if (styleConfig.PalletsControl !== 'gradient' && styleConfig.PalletsControl !== 'custom') aux.PalletsControl = 'default';
      if (!styleConfig.ColorTheme) aux.ColorTheme = { scheme: 'nivo' };
      // console.log('random Colors');
      pivotTable.columns.forEach((col) => {
        const key = Array.isArray(col) ? col[col.length - 1] : col;
        if (!tmp[key] && !styleConfig.Colors[key]) tmp[key] = randomColor();
      });
      // console.log('criando cores randomicas "por linha"');
      pivotTable.index.forEach((ind, i) => {
        if (!tmp[i] && !styleConfig.Colors[i]) tmp[i] = randomColor();
        if (!tmp[ind] && !styleConfig.Colors[ind]) tmp[ind] = randomColor();
      });

      aux.Colors = { ...styleConfig.Colors, ...tmp };
    }
    return aux;
  }

  function createStyleConfig(type) {
    if (editLoad) {
      // console.log(styleConfig);
      setStyleConfig({ ...getDefaultStyle(type), ...styleConfig, type });
      setEditLoad(false);
      return;
    }
    const obj = { ...getDefaultStyle(type), type };

    // switch (type) {
    //   case 'Bar':
    //     obj = { ...BarInitialConfig, type };
    //     break;
    //   case 'Pie':
    //     obj = { ...PieInitialConfig, type };
    //     break;
    //   case 'Line':
    //     obj = { ...LineInitialConfig, type };
    //     break;
    //   case 'Value':
    //     obj = { ...ValueInitialConfig, type };
    //     break;
    //   case 'Radar':
    //     obj = { ...RadarInitialConfig, type };
    //     break;
    //   case 'Table':
    //     obj = { ...TableInitialConfig, type };
    //     break;
    //   case 'Map':
    //     obj = { ...MapInitialConfig, type };
    //     break;
    //   case 'Funnel':
    //     obj = { ...FunnelInitialConfig, type };
    //     break;
    //   case 'RadialBar':
    //     obj = { ...RadialBarInitialConfig, type };
    //     break;
    //   case 'Calendar':
    //     obj = { ...CalendarInitialConfig, type };
    //     break;
    //   case 'Waffle':
    //     obj = { ...WaffleInitialConfig, type };
    //     break;
    //   case 'BoxPlot':
    //     obj = { ...BoxPlotInitialConfig, type };
    //     break;
    //   default:
    //     break;
    // }

    if (styleConfig?.TitleControl) obj.TitleControl = styleConfig.TitleControl;
    if (styleConfig?.StyleTitleControl) obj.StyleTitleControl = styleConfig.StyleTitleControl;

    // if (pivotTable && chartType !== 'Map') {
    //   // Vai criar cores randomicas para quando o cara criar o gráfico n começar tudo preto...
    //   const tmp = {};
    //   if (!styleConfig.Colors) styleConfig.Colors = {};
    //   pivotTable.columns.forEach((col) => {
    //     const key = Array.isArray(col) ? col[col.length - 1] : col;
    //     if (!tmp[key] && !styleConfig.Colors[key]) tmp[key] = randomColor();
    //   });
    //   // console.log('criando cores randomicas "por linha"');
    //   pivotTable.index.forEach((ind, i) => {
    //     if (!tmp[i] && !styleConfig.Colors[i]) tmp[i] = randomColor();
    //     if (!tmp[ind] && !styleConfig.Colors[ind]) tmp[ind] = randomColor();
    //   });

    //   obj.Colors = { ...styleConfig.Colors, ...tmp };
    //   // }
    // }

    // obj = fillColorsWithRandoms(obj);
    // fix titleControl n pegando o name do gráfico. serve para gŕaficos antigos
    if (!obj.TitleControl && obj.name) obj.TitleControl = obj.name;

    obj.CustomSortControl = {
      checked: false, kpiCategories: [],
    };

    const normalizeTableSorts = {
      Crescente: 'Alfabética A-Z',
      Decrescente: 'Alfabética Z-A',
      get(sortValue) {
        return this[sortValue] || sortValue;
      },
    };

    if (styleConfig?.SortValuesControl && styleConfig.SortValuesControl !== 'Customizado') {
      if (type === 'Table') {
        switch (styleConfig.SortValuesControl) {
          case 'Alfabética A-Z':
          case 'Valor Crescente':
            obj.SortValuesControl = 'Crescente';
            obj.ColumnToSortControl = 'index';
            break;
          case 'Alfabética Z-A':
          case 'Valor Decrescente':
            obj.SortValuesControl = 'Decrescente';
            obj.ColumnToSortControl = 'index';
            break;
          default:
            break;
        }
      } else {
        obj.SortValuesControl = normalizeTableSorts.get(styleConfig.SortValuesControl) || 'Não ordenado';
      }
    }

    /* obj.CustomSortControl = {
      ...styleConfig?.CustomSortControl,
      kpiColumns: styleConfig?.CustomSortControl?.kpiColumns || [],
      kpiCategories: styleConfig?.CustomSortControl?.kpiCategories || [],
      kpiCategoriesFixed: [...(pivotTable ? pivotTable?.index.filter((idx) => chartType === 'Table' || idx !== 'Totais') : [])],
      kpiColumnsFixed: pivotTable?.columns.length > 1
        ? [...pivotTable?.columns.map((c) => c.slice(1))] : [],
    };
    /* obj.SortValuesControlLine = {
      ...styleConfig.SortValuesControlLine,
      options: pivotTable?.columns.length > 1 ? ['Não ordenado', 'Alfabética A-Z', 'Alfabética Z-A', 'Customizado'] : ['Não ordenado', 'Alfabética A-Z', 'Alfabética Z-A', 'Valor Crescente', 'Valor Decrescente', 'Customizado'],
    }; */

    if (!obj) return;
    setStyleConfig(obj);
  }

  const resetCustomSort = () => {
    setStyleConfig((p) => ({
      ...p,
      CustomSortControl: {
        checked: false, kpiCategories: [],
      },
      SortValuesControl: p.SortValuesControl === 'Customizado' ? 'Não ordenado' : p.SortValuesControl,
    }));
  };

  useEffect(() => {
    if (pivotTable && styleConfig.PalletsControl === 'custom') {
      const colorsAux = { ...(styleConfig.Colors ?? {}) };
      let isDirty = false;
      if (styleConfig.type === 'BoxPlot' && pivotTable.columns?.[0]) {
        const key = Array.isArray(pivotTable.columns[0])
          ? pivotTable.columns[0][0] : pivotTable.columns[0];
        if (!colorsAux[key]) {
          colorsAux[key] = randomColor();
          isDirty = true;
        }
      } else if (styleConfig.type === 'ScatterPlot' && typeof pivotTable.index?.[0] !== 'string') {
        colorsAux.Valores = randomColor();
        isDirty = true;
      } else {
        pivotTable.columns.forEach((col) => {
          const key = Array.isArray(col) ? col[col.length - 1] : col;
          if (!colorsAux[key]) {
            colorsAux[key] = randomColor();
            isDirty = true;
          }
        });
      }
      pivotTable.index.forEach((idx) => {
        if (Array.isArray(idx)) {
          idx.forEach((i) => {
            if (!colorsAux[i]) {
              colorsAux[i] = randomColor();
              isDirty = true;
            }
          });
        } else if (!colorsAux[idx]) {
          colorsAux[idx] = randomColor();
          isDirty = true;
        }
      });
      if (isDirty) {
        setStyleConfig((o) => ({
          ...o, Colors: { ...colorsAux },
        }));
      }
    }
  }, [pivotTable, styleConfig?.PalletsControl]);

  useEffect(() => {
    createStyleConfig(chartType);
  }, [chartType]);

  // useEffect(() => {
  //   // if (!chartType) setChartType('Table');
  //   if (!Object.keys(styleConfig).length) return;
  //   const objColors = fillColorsWithRandoms(styleConfig);
  //   setStyleConfig((p) => ({
  //     ...p,
  //     ...objColors,
  //     /*
  //       ...p?.CustomSortControl,
  //       kpiColumns: p?.CustomSortControl?.kpiColumns || [],
  //       kpiCategoriesFixed: [...(pivotTable ? pivotTable?.index.filter((idx) => chartType === 'Table' || idx !== 'Totais') : [])],
  //       kpiColumnsFixed: pivotTable?.columns.length > 1
  //         ? [...pivotTable?.columns.map((c) => c.slice(1))] : [],
  //     },
  //     SortValuesControlLine: {
  //       ...styleConfig.SortValuesControlLine,
  //       options: pivotTable?.columns.length > 1 ? ['Não ordenado', 'Alfabética A-Z', 'Alfabética Z-A', 'Customizado'] : ['Não ordenado', 'Alfabética A-Z', 'Alfabética Z-A', 'Valor Crescente', 'Valor Decrescente', 'Customizado'],
  //     }, */
  //   }));
  // }, [pivotTable]);

  /**
   * Permite a saida da pagina quando a modal de confirmacao aparecer
   */
  const handleConfirmNavigationClick = () => {
    setConfirmedNavigation(true);
  };

  // Bloqueia rotas do react
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Bloqueia rotas tradicionais
  useEffect(() => {
    const unload = (event) => {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = !confirmedNavigation ? 'Você perderá todas as modificações, tem certeza que deseja sair?' : '';
    };
    window.addEventListener('beforeunload', unload);
    return () => window.removeEventListener('beforeunload', unload);
  }, [confirmedNavigation]);

  /**
   * Modal de confirmacao de saida da pagina
   * @param {Location} nextLocation O caminho ao qual o usuario esta tentando acessar
   */
  const openConfirmExitModal = (nextLocation) => {
    setLastLocation(nextLocation);
    setModalConfig({
      title: 'Aviso',
      yesFunc: handleConfirmNavigationClick,
      yesLabel: 'Sair mesmo assim',
      notLabel: 'Ficar aqui',
      children: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 style={{ textAlign: 'center', margin: '0 0 5px' }}>
            Tem certeza que deseja sair?
          </h3>
          <span style={{ fontSize: '1rem' }}>
            Você perderá todas as modificações.
          </span>
        </div>
      ),
    });
  };

  /**
   * Bloqueia ou permite a saida da pagina, baseado no componente Prompt do react-router-dom
   * @param {Location} nextLocation O caminho ao qual o usuario esta tentando acessar
   * @returns Booleana de permissao de saida para o Prompt
   */
  const handleBlockedRoute = (nextLocation) => {
    if (blockNav.current && !confirmedNavigation && (selectedDatabase.value !== '' || pivotTable)) {
      openConfirmExitModal(nextLocation);
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   if (isMounted.current) {
  //     const invalids = {
  //       nan: true,
  //       0: true,
  //       null: true,
  //       NaN: true,
  //     };
  //     if (chartType === 'Pie' && pivotTable?.data.every(((d) => !d[0] || invalids[d[0]]))) {
  //       setAlertConfig({
  //         type: 'warning',
  //         text: 'Todas as categorias possuem valores nulos ou zerados, logo não é possível renderizar o gráfico de pizza.',
  //       });
  //       setNullValues(true);
  //       return;
  //     }

  //     setNullValues(false);
  //   }
  // }, [pivotTable, chartType]);

  useEffect(() => {
    if (selectedDatabase.value !== '') {
      (async () => {
        const {
          error: er, res, msg, raw,
        } = await filesAPI.getFileSample(selectedDatabase.value, false);
        if (er) {
          goBack();
          setAlertConfig({
            type: 'error',
            text: msg,
            child: raw,
          });
          goBack();
        } else {
          const tempArray = res
            ? res.columns
              .filter((val) => val !== 'index')
              .map((val) => ({
                label: val,
                value: val,
                type: res.types[val],
                id: val,
              }))
            : [];

          setDataFrameOptions(tempArray);
          setDataFrame(dataFrameToNewDataTable(res, theme));
        }
      })();
    }
  }, [selectedDatabase, filesAPI]);

  function handleStyleConfig(value, diff) {
    const newConfig = handleStyleConf(styleConfig, diff, value);
    setStyleConfig({ ...newConfig });
  }

  const kpiNameRef = useRef(null);

  const openDataFrameModal = () => {
    setModalConfig({
      title: selectedDatabase.label,
      children: !!dataFrame && (
        <>
          <div style={{
            maxHeight: 'calc(90vh - 145px)',
            overflow: 'auto',
            padding: '5px',
            width: '100%',
            maxWidth: '900px',
          }}
          >
            <DataTable
              columns={dataFrame.columns}
              rowData={dataFrame.rowData}
              headerColor="transparent"
              theadStyle={{
                position: 'sticky',
                top: '-5px',
                zIndex: 1,
                backgroundColor: theme.modal,
              }}
              defaultSortField="index"
              style={{ width: 'max-content' }}
              indexAsHeader
            />
          </div>
          <LegendsContainer>
            <div className="legend-type">
              <div className="square legend-texto" />
              Texto
            </div>
            <div className="legend-type">
              <div className="square legend-numero" />
              Número
            </div>
            <div className="legend-type">
              <div className="square legend-date" />
              Data
            </div>
          </LegendsContainer>
        </>
      ),
    });
  };

  const yFunc = async (kpiName, s) => {
    blockNav.current = false;
    const rawSC = {
      ...s,
      TitleControl: kpiName,
      type: chartType,
      // Reset custom colors and order when not used
      Colors: s.PalletsControl === 'custom' ? s.Colors : {},
      CustomSortControl: {
        checked: s.CustomSortControl?.checked || false,
        kpiCategories: s.CustomSortControl?.checked ? s.CustomSortControl.kpiCategories : [],
      },
    };
    const defaultSC = getDefaultStyle(chartType);
    /** List of keys to keep */
    const alwaysKeep = [
      'GlobalValue', 'ShowTitleControl', 'StyleTitleControl', 'type', 'TitleControl', 'TotalFunctionControl',
    ];
    // Remove attributes that have not changed from the default
    const tmp = Object.entries(rawSC).reduce((aux, [k, v]) => {
      if (alwaysKeep.includes(k)) aux[k] = v;
      else if (defaultSC[k] !== undefined && !isEqual(v, defaultSC[k])) aux[k] = v;
      return aux;
    }, {});

    try {
      const res = await gridKpiAPI
        .saveKpiCard(
          kpiName,
          kpiKey,
          chartType,
          kpisLayout,
          metaGen,
          metaGridInfo,
          pivotTable,
          tmp,
        );
      if (res.error) {
        setAlertConfig({
          type: 'error',
          text: res.msg,
        });
        blockNav.current = true;
        return;
      } goBack();
    } catch (er) {
      console.log(er);
    }
  };

  function haveTitleModal(title, s) {
    setModalConfig({
      title: 'Salvar KPI',
      notLabel: 'Cancelar',
      yesLabel: 'Salvar',
      yesFunc: () => { yFunc(title, s); if (tourOpen) nextStep(); },
      confirmClassName: 'submit_name_btn',
      children: (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
        }}
        // className='submit_name_btn'
        >
          <span style={{ fontSize: '1.2rem', padding: '10px 0px' }}>
            Tudo pronto para salvar o KPI:
            {' '}
            <b>
              {title}
            </b>
          </span>
          <span>Tem certeza que deseja continuar?</span>
        </div>
      ),
    });
  }

  function notHaveTitleModal() {
    const onSubmit = (e) => {
      if (tourOpen) nextStep();
      e.preventDefault();
      handleStyleConfig(kpiNameRef.current.value, 'TitleControl');
      haveTitleModal(kpiNameRef.current.value, styleConfig);
    };
    setModalConfig({
      title: 'Salvar KPI',
      className: 'title_modal',
      children: (
        <div
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
          }}
          className="title_modal"
        >
          <form
            onSubmit={onSubmit}
            style={{
              display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',
            }}
          >
            <span style={{ fontSize: '1rem', paddingTop: '10px' }}>
              <b>Você não colocou nenhum título para o seu KPI</b>
            </span>
            <span style={{ fontSize: '0.9rem', padding: '10px 0px', width: '100%' }}>
              Para continuar, adicione um abaixo!
            </span>
            <InputTextLine
              label="Nome do KPI"
              required
              ref={kpiNameRef}
              error={false}
              styleContainer={{ width: '100%' }}
            />
            <Button
              type="submit"
              style={{ margin: '10px 0px' }}
              className="continue-btn"
            >
              Continuar
            </Button>
          </form>
        </div>
      ),
    });
  }

  function openSaveModal() {
    if (tourOpen) nextStep();
    if (styleConfig.TitleControl) haveTitleModal(styleConfig.TitleControl, styleConfig);
    else notHaveTitleModal();
  }

  /* Modal anterior
  const openReturnConfirmModal = () => {
    setModalConfig({
      title: 'Retornar',
      yesFunc: () => goBack(),
      yesLabel: 'Sair mesmo assim',
      // notFunc: toggleModal,
      notLabel: 'Ficar aqui',
      children: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              padding: '10px 0px',
            }}
          >
            Tem certeza que deseja sair?
          </span>
          <span style={{ fontSize: '1rem' }}>
            Você perderá todas as modificações.
          </span>
        </div>
      ),
    });
  };

  const handleClickReturn = () => {
    if (selectedDatabase !== '' || pivotTable) {
      return openReturnConfirmModal();
      // return toggleReturnConfirm();
    }
    return goBack();
  };
  */

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <KpiCreateToolbar
        kpiTitle={styleConfig?.TitleControl}
        dashboardTitle={dashboardDoc?.name}
        handleClickReturn={goBack}
        openSaveModal={openSaveModal}
        disableSave={!pivotTable}
        saveTitle={(newTitle) => handleStyleConfig(newTitle, 'TitleControl')}
      />
      <OuterKpiCreate>
        {/* ultima coisa a mudar começo */}
        <StyledGridContainerCreateKpi>
          <StyledContainer className="fileInfo">
            <div
              className="middleDiv"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Select
                alphabeticalOrder
                isSearchable
                selectLabel="Selecione o arquivo"
                onChange={(newValue) => {
                  setSelectedDatabase(newValue);
                  setRefreshSideBar(!refreshSideBar);
                }}
                // fullWidth
                options={fileTreeOptions.map((val) => ({
                  label: val.filename.replace('.metrics', ''),
                  value: val.file_id,
                  id: val.file_id || val.filename,
                  owner: val.owner,
                }))}
                formatOptionLabel={false}
                fullWidth
                value={selectedDatabase || { label: '', value: '', id: '' }}
                tourContext={{ tourOpen, nextStep }}
              />

              {selectedDatabase.value !== '' ? (
                <Tooltip text="Visualizar Dados">
                  <IconButton color="primary" onClick={openDataFrameModal} style={{ marginLeft: 5 }}>
                    <Eye2 />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </StyledContainer>
          <KpiCarousel
            setChartType={setChartType}
            selectedChartType={chartType}
            disabled={pivotTable === null}
          />

          {!!dataFrame && (
            <StyledContainer className="sideBar">
              <KpiSideBar
                refresh={refreshSideBar}
                selectedDatabase={selectedDatabase}
                dataFrameOptions={dataFrameOptions}
                dataFrameTypes={dataFrame.types}
                setPivotTable={setPivotTable}
                setMetaGen={setMetaGen}
                pivotTable={pivotTable}
                toEditOptions={kpiEditOptions}
                chartType={chartType}
                styleConfig={styleConfig}
                handleStyleConfig={handleStyleConfig}
                showGenChartAlert={showGenChartAlert}
                setShowGenChartAlert={setShowGenChartAlert}
                resetCustomSort={resetCustomSort}
                isGlobalThemeActive={Boolean(dashboardDoc?.cardStyles?.useGlobalTheme)}
              />
            </StyledContainer>
          )}

          {/* {!!dataFrame && ( */}
          <StyledContainer
            className="main"
            image={null}
            chartType={chartType}
            disabled={pivotTable === null}
            style={{ position: 'relative' }}
          >
            {pivotTable === null ? (
              <div className="content">
                <section>
                  <b>Para criar um KPI selecione um arquivo</b>
                </section>
                <NoFileKpis />
              </div>
            ) : (
              showGenChartAlert && (
                <GenChartAlert>
                  <IconCircle>
                    <Warning />
                  </IconCircle>
                  <h2>Clique em &quot;Gerar Gráfico&quot; para aplicar as mudanças</h2>
                  {(metaGen?.filterValues?.length > 0 || metaGen?.groupValues?.length > 0) && (
                    <h4 style={{ marginTop: '0' }}>(Filtros e agrupamentos de valores serão removidos)</h4>
                  )}
                  <Button
                    onClick={() => setShowGenChartAlert(false)}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                  >
                    Ok
                  </Button>
                </GenChartAlert>
              )
            )}
            {pivotTable === null ? (
              null
            ) : (
              <KpiRender
                dashboardKey={dashboardKey}
                metaGen={metaGen}
                chartType={chartType}
                pivotTable={pivotTable}
                styleConfig={styleConfig}
                setMetaGridInfo={setMetaGridInfo}
                handleStyleConfig={handleStyleConfig}
                database={selectedDatabase?.value}
                handleDynamicKpi={handleDynamicKpi}
                dashboardLayout={kpisLayout?.lg.find((ds) => ds.i === kpiKey)}
                dashboardDoc={dashboardDoc}
              />
            )}
          </StyledContainer>
          {/* )} */}
        </StyledGridContainerCreateKpi>
        {(kpiLoading || filesState.isLoading) && <Loader />}
      </OuterKpiCreate>
    </>
  );
}

KpiCreate.propTypes = {
  dashboardKey: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  kpiKey: PropTypes.string.isRequired,
  handleDynamicKpi: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
